import React, { createElement, useEffect } from "react";

import { useStyletron } from "baseui";
import {
  HeadingXXLarge,
  HeadingMedium,
  LabelSmall,
  ParagraphLarge,
  LabelMedium,
  DisplayXSmall,
} from "baseui/typography";

import { Grid, Cell } from "../../components/ui";
import {
  ContentObject,
  FormattedContentItem,
  ContentItem,
} from "../../components/content";

import Icons from "../../assets/images/icons";
import dotGridBox from "../../assets/images/dot-grid-box.svg";
import dotGridWhiteBox from "../../assets/images/dot-grid-white-box.svg";
import cloudTop1 from "../../assets/images/cloud-top-1.svg";
import cloudTop2 from "../../assets/images/cloud-top-2.svg";
import cloudBottom from "../../assets/images/cloud-bottom.svg";

import { useTheme } from "../../contexts/theme";

import VisibilitySensor from "react-visibility-sensor";
import { Spring } from "react-spring/renderprops";

const Timeline = () => {
  const [css, theme] = useStyletron();
  const { useDarkTheme } = useTheme();

  let Motus;

  if (typeof window !== "undefined" && window) Motus = require("motus");

  useEffect(() => {
    if (typeof window !== "undefined" && window) {
      if (Motus && Motus.default && Motus.default.Animation) {
        Motus.default.clearAnimations();
        Motus.default.addAnimation(
          new Motus.default.Animation({
            startPoint:
              document.querySelector("[data-csweb=about-us-timeline]")
                .offsetTop -
              window.innerHeight / 2,
            endPoint:
              document
                .querySelector("[data-csweb=about-us-timeline]")
                .getBoundingClientRect().bottom - window.innerHeight,
            $el: document.querySelector("[data-csweb=timeline-vertical-line]"),
            keyframes: [
              {
                height: 0,
              },
              {
                height:
                  document.querySelector("[data-csweb=about-us-timeline]")
                    .clientHeight - 600,
              },
            ],
          })
        );
      }
    }
  }, []);

  useEffect(
    () => () => {
      Motus.default.clearAnimations();
    },
    []
  );

  return (
    <article
      data-csweb="about-us-timeline"
      className={css({
        scrollMarginTop: "70px",
        position: "relative",
      })}
    >
      <div
        className={css({
          marginTop: "-5vh",
          width: "100vw",
          overflowX: "hidden",
          overflowY: "scroll",
        })}
      >
        <Grid>
          <Cell span={[2, 4, 6]}>
            <div
              className={css({
                position: "relative",
              })}
            >
              <div
                className={css({
                  position: "absolute",
                  zIndex: "0",
                  right: "-18px",
                  height: "5vh",
                  width: "50vw",
                  backgroundColor: theme.colors.backgroundPrimary,
                  borderTopRightRadius: "30px",
                  [theme.breakpoints.mediaQueries.medium]: {
                    right: "-8px",
                  },
                  [theme.breakpoints.mediaQueries.small]: {
                    right: "50%",
                  },
                })}
              ></div>
            </div>
          </Cell>
          <Cell span={[2, 4, 6]}>
            <div
              className={css({
                position: "relative",
              })}
            >
              <div
                className={css({
                  position: "absolute",
                  zIndex: "0",
                  left: "-18px",
                  height: "5vh",
                  width: "50vw",
                  backgroundColor: theme.colors.backgroundPrimary,
                  borderTopLeftRadius: "30px",
                  [theme.breakpoints.mediaQueries.medium]: {
                    left: "-8px",
                  },
                  [theme.breakpoints.mediaQueries.small]: {
                    left: "50%",
                  },
                })}
              ></div>
            </div>
          </Cell>
          <Cell skip={[0, 0, 1]} span={[4, 8, 10]}>
            <div
              className={css({
                paddingTop: theme.sizing.scale1200,
                paddingBottom: theme.sizing.scale200,
              })}
            >
              <HeadingXXLarge
                $style={{
                  textAlign: "center",
                }}
              >
                <FormattedContentItem
                  scope="AboutUs"
                  accessor="timeline.heading"
                />
              </HeadingXXLarge>
            </div>
          </Cell>
        </Grid>

        <div
          data-csweb="timeline-vertical-line"
          className={css({
            position: "absolute",
            left: "50%",
            top: "200px",
            transform: "translateX(-50%)",
            height: "100%",
            width: "2px",
            margin: "auto",
            display: "block",
            backgroundImage: `linear-gradient(${theme.colors.blue50} 50%, rgba(255,255,255,0) 0%)`,
            backgroundPosition: "right",
            backgroundSize: "2px 10px",
            backgroundRepeat: "repeat-y",
            [theme.breakpoints.mediaQueries.medium]: {
              display: "none",
            },
          })}
        ></div>
        {ContentObject.AboutUs.timeline.items.map((item, index) => (
          <VisibilitySensor
            partialVisibility
            key={index}
            offset={{
              top: -10000,
              bottom:
                typeof window !== "undefined" && window
                  ? window.innerHeight / 4
                  : 0,
            }}
          >
            {({ isVisible }) => (
              <Spring
                delay={0}
                config={{ tension: 120, friction: 14, duration: 400 }}
                to={{ opacity: isVisible ? 1 : 0, scale: isVisible ? 1 : 0 }}
              >
                {({ scale, ...style }) => {
                  return (
                    <div
                      data-csweb="spring-wrapper"
                      className={css({ ...style })}
                    >
                      <Grid key={index}>
                        <Cell
                          skip={[0, 0, item.align === "left" ? 0 : 1]}
                          span={[4, 3, 4]}
                          order={[2, item.align === "left" ? 3 : 1]}
                        >
                          <div
                            className={css({
                              paddingTop: theme.sizing.scale3200,
                              paddingBottom: theme.sizing.scale1000,
                              textAlign: item.align,
                              [theme.breakpoints.mediaQueries.medium]: {
                                paddingTop: theme.sizing.scale1200,
                              },
                            })}
                          >
                            <HeadingMedium
                              marginTop="scale0"
                              marginBottom="scale200"
                              $style={{
                                [theme.breakpoints.mediaQueries.medium]: {
                                  textAlign: "center",
                                },
                              }}
                            >
                              {item.title}
                            </HeadingMedium>
                            <LabelSmall
                              $style={{
                                [theme.breakpoints.mediaQueries.medium]: {
                                  textAlign: "center",
                                },
                              }}
                            >
                              {item.subtitle}
                            </LabelSmall>
                            <ParagraphLarge
                              $style={{
                                [theme.breakpoints.mediaQueries.medium]: {
                                  textAlign: "center",
                                  paddingLeft: theme.sizing.scale500,
                                  paddingRight: theme.sizing.scale500,
                                },
                              }}
                            >
                              <FormattedContentItem
                                scope="AboutUs"
                                accessor={`timeline.items.${index}.caption`}
                              />
                            </ParagraphLarge>
                          </div>
                        </Cell>
                        <Cell key={index} span={[0, 2, 2]} order={2}>
                          <div
                            className={css({
                              width: "100%",
                              height: "100%",
                              position: "relative",
                              transform: `scale(${scale})`,
                              [theme.breakpoints.mediaQueries.medium]: {
                                display: "none",
                              },
                            })}
                          >
                            <div
                              className={css({
                                position: "absolute",
                                width: item.isBig ? "100px" : "80px",
                                height: item.isBig ? "100px" : "80px",
                                borderTopLeftRadius: "50%",
                                borderTopRightRadius: "50%",
                                borderBottomRightRadius: "50%",
                                borderBottomLeftRadius: "50%",
                                background: theme.colors.backgroundLightAccent,
                                zIndex: "2",
                                left: "50%",
                                top: "50%",
                                transform: "translate(-50%, -50%)",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              })}
                            >
                              <LabelMedium color="white">
                                {item.year}
                              </LabelMedium>
                            </div>
                            <div
                              className={css({
                                position: "absolute",
                                width: item.isBig ? "120px" : "95px",
                                height: item.isBig ? "120px" : "95px",
                                zIndex: "1",
                                left: "50%",
                                top: "50%",
                                transform: "translate(-50%, -50%)",
                                overflow: "hidden",
                              })}
                            >
                              <div
                                className={css({
                                  position: "absolute",
                                  width: item.isBig ? "120px" : "95px",
                                  height: item.isBig ? "120px" : "95px",
                                  zIndex: "1",
                                  borderTopLeftRadius: "50%",
                                  borderTopRightRadius: "50%",
                                  borderBottomRightRadius: "50%",
                                  borderBottomLeftRadius: "50%",
                                  clipPath:
                                    index % 4 === 0
                                      ? `polygon(0% 0%, 50% 0%, 50% 50%, 0% 50%)`
                                      : (index - 1) % 4 === 0
                                      ? `polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%)`
                                      : (index - 2) % 4 === 0
                                      ? `polygon(0% 50%, 50% 50%, 50% 100%, 0% 100%)`
                                      : `polygon(50% 0%, 100% 0%, 100% 50%, 50% 50%)`,
                                  animationDuration: "1000ms",
                                  animationFillMode: "both",
                                  animationTimingFunction: "ease-in-out",
                                  animationIterationCount: "1",
                                  animationName: isVisible
                                    ? {
                                        from: {
                                          transform: "rotate(0deg)",
                                        },
                                        to: {
                                          transform: "rotate(-360deg)",
                                        },
                                      }
                                    : {},
                                  "::before": {
                                    content: "''",
                                    position: "absolute",
                                    left: "0px",
                                    top: "0px",
                                    right: "0px",
                                    bottom: "0px",
                                    borderTopLeftRadius: "50%",
                                    borderTopRightRadius: "50%",
                                    borderBottomRightRadius: "50%",
                                    borderBottomLeftRadius: "50%",
                                    background:
                                      "linear-gradient(135deg, rgba(60,0,220,1) 0%, rgba(0,220,230,1) 100%)",
                                    animationDuration: "1000ms",
                                    animationFillMode: "both",
                                    animationTimingFunction: "ease-in-out",
                                    animationIterationCount: "1",
                                    animationName: isVisible
                                      ? {
                                          from: {
                                            transform: "rotate(0deg)",
                                          },
                                          to: {
                                            transform: "rotate(360deg)",
                                          },
                                        }
                                      : {},
                                  },
                                })}
                              ></div>
                            </div>
                          </div>
                        </Cell>
                        <Cell
                          key={index}
                          span={[4, 3, 4]}
                          skip={[0, 0, item.align === "left" ? 1 : 0]}
                          order={[1, item.align === "left" ? 1 : 3]}
                          align="center"
                        >
                          {createElement(Icons[item.icon], {
                            className: css({
                              margin: "auto",
                              display: "block",
                              height: item.iconHeight || "200px",
                              strokeDashoffset: "1500",
                              strokeDasharray: "1500",
                              animationDuration: "2000ms",
                              animationFillMode: "both",
                              animationTimingFunction: "linear",
                              animationIterationCount: "1",
                              animationName: isVisible
                                ? {
                                    from: {
                                      strokeDashoffset: "1500",
                                    },
                                    to: {
                                      strokeDashoffset: "0",
                                    },
                                  }
                                : {},
                              [theme.breakpoints.mediaQueries.medium]: {
                                marginTop: theme.sizing.scale1000,
                                height: "140px",
                                maxWidth: "140px",
                              },
                            }),
                          })}
                          <img
                            alt="Dot grid background"
                            className={css({
                              position: "absolute",
                              marginTop: "-240px",
                              zIndex: "0",
                              pointerEvents: "none",
                            })}
                            src={useDarkTheme ? dotGridWhiteBox : dotGridBox}
                          />
                        </Cell>
                      </Grid>
                    </div>
                  );
                }}
              </Spring>
            )}
          </VisibilitySensor>
        ))}
        <Grid>
          <Cell skip={[0, 0, 3]} span={[4, 8, 6]}>
            <div
              className={css({
                paddingTop: theme.sizing.scale2400,
                paddingBottom: theme.sizing.scale2400,
                display: "flex",
                justifyContent: "center",
                [theme.breakpoints.mediaQueries.medium]: {
                  paddingBottom: theme.sizing.scale800,
                },
              })}
            >
              <div
                className={css({
                  position: "relative",
                })}
              >
                <img
                  alt="Cloud"
                  className={css({
                    position: "absolute",
                    left: "10px",
                    top: "-40px",
                    zIndex: "0",
                    pointerEvents: "none",
                  })}
                  width="150px"
                  src={cloudTop1}
                />
                <img
                  alt="Cloud"
                  className={css({
                    position: "absolute",
                    right: "10px",
                    top: "-80px",
                    zIndex: "0",
                    pointerEvents: "none",
                  })}
                  width="150px"
                  src={cloudTop2}
                />
                <DisplayXSmall
                  color="blue50"
                  display="inline-block"
                  marginTop="scale1000"
                  marginBottom="scale1000"
                  $style={{ textAlign: "center" }}
                >
                  <ContentItem scope="AboutUs" accessor="timeline.label" />
                </DisplayXSmall>

                <img
                  className={css({
                    position: "absolute",
                    left: "25%",
                    top: "85%",
                    zIndex: "0",
                    pointerEvents: "none",
                  })}
                  width="150px"
                  src={cloudBottom}
                  alt="Cloud"
                />
              </div>
            </div>
          </Cell>
        </Grid>
      </div>
    </article>
  );
};

export default Timeline;
