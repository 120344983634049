import React, { useState } from "react";

import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { LabelLarge, ParagraphLarge } from "baseui/typography";

import { Grid, Cell } from "../../components/ui";
import {
  ContentObject,
  ContentItem,
  FormattedContentItem,
} from "../../components/content";

import panelsImg1 from "../../assets/images/panels-1.png";
import panelsImg2 from "../../assets/images/panels-2.png";
import shine from "../../assets/images/shine.svg";

import { Spring } from "react-spring/renderprops";

const Panels = () => {
  const [css, theme] = useStyletron();
  const [panelIndex, setPanelIndex] = useState(0);

  if (ContentObject.AboutUs.panels) {
    return (
      <article
        data-csweb="about-us-panels"
        className={css({
          marginTop: theme.sizing.scale2400,
          marginBottom: theme.sizing.scale2400,
          position: "relative",
          [theme.breakpoints.mediaQueries.medium]: {
            marginBottom: theme.sizing.scale1200,
          },
        })}
      >
        <div
          className={css({
            marginTop: theme.sizing.scale3200,
            marginBottom: theme.sizing.scale4800,
            backgroundColor: theme.colors.backgroundSecondary,
            paddingBottom: theme.sizing.scale4800,
            display: "block",
            [theme.breakpoints.mediaQueries.medium]: {
              paddingBottom: theme.sizing.scale1200,
              marginBottom: theme.sizing.scale1200,
            },
          })}
        >
          <Grid>
            <Cell skip={[0, 0, 1]} span={[4, 2, 2]}>
              <ul
                className={css({
                  marginTop: theme.sizing.scale1400,
                  marginBottom: theme.sizing.scale4800,
                  listStyle: "none",
                  [theme.breakpoints.mediaQueries.medium]: {
                    marginBottom: theme.sizing.scale900,
                    textAlign: "center",
                    paddingLeft: "0px",
                  },
                })}
              >
                {ContentObject.AboutUs.panels.map((panel, index) => {
                  return (
                    <li key={index}>
                      <LabelLarge
                        onClick={() => setPanelIndex(index)}
                        color={
                          panelIndex === index
                            ? "contentPrimary"
                            : "contentAccent"
                        }
                        className={css({
                          position: "relative",
                          display: "inline-block",
                          lineHeight: "2",
                          marginTop: theme.sizing.scale100,
                          marginBottom: theme.sizing.scale100,
                          paddingTop: theme.sizing.scale200,
                          paddingBottom: theme.sizing.scale200,
                          transitionProperty: "all",
                          transitionDuration: "100ms",
                          transitionTimingFunction: "ease-in-out",
                          fontWeight: panelIndex === index ? "700" : "100",
                          "::before": {
                            content: panelIndex === index ? "" : "''",
                            position: "absolute",
                            bottom: "0px",
                            left: "0px",
                            right: "0px",
                            height: "1px",
                            background: theme.colors.backgroundLightAccent,
                          },
                          "::after": {
                            content: panelIndex === index ? "" : "''",
                            position: "absolute",
                            bottom: "0px",
                            left: "0px",
                            height: "1px",
                            width: "0%",
                            background: theme.colors.contentAccent,
                            transitionProperty: "all",
                            transitionDuration: "300ms",
                            transitionTimingFunction: "ease-in-out",
                          },
                          ":hover": {
                            cursor: "pointer",
                          },
                          ":hover::after": {
                            width: "100%",
                          },
                        })}
                      >
                        {panel.title}
                      </LabelLarge>
                    </li>
                  );
                })}
              </ul>
            </Cell>
            <Cell span={[4, 3, 4]}>
              <Spring
                key={panelIndex}
                config={{ tension: 120, friction: 14, duration: 200 }}
                from={{ opacity: 0.8, top: -100 }}
                to={{ opacity: 1, top: 0 }}
              >
                {({ top, ...style }) => {
                  return (
                    <div
                      className={css({
                        width: "100%",
                        height: `calc(100% + ${theme.sizing.scale3200} + ${theme.sizing.scale4800} + ${theme.sizing.scale4800})`,
                        display: "flex",
                        marginTop: `-${theme.sizing.scale4800}`,
                        flexDirection: "column",
                        alignItems: "flex-end",
                        transform: `translateY(${top}px)`,
                        ...style,
                        [theme.breakpoints.mediaQueries.medium]: {
                          marginTop: "0px",
                          height: "auto",
                          flexDirection: "row",
                          alignItems: "stretch",
                        },
                      })}
                    >
                      <div
                        className={css({
                          height: "45%",
                          width: "75%",
                          borderTopWidth: "1px",
                          borderRightWidth: "1px",
                          borderBottomWidth: "1px",
                          borderLeftWidth: "1px",
                          borderTopStyle: "solid",
                          borderRightStyle: "solid",
                          borderBottomStyle: "solid",
                          borderLeftStyle: "solid",
                          borderTopColor: theme.colors.backgroundLightAccent,
                          borderRightColor: theme.colors.backgroundLightAccent,
                          borderBottomColor: theme.colors.backgroundLightAccent,
                          borderLeftColor: theme.colors.backgroundLightAccent,
                          backgroundImage: `url(${panelsImg2})`,
                          backgroundSize: "cover",
                          position: "relative",
                          [theme.breakpoints.mediaQueries.medium]: {
                            display: "none",
                          },
                        })}
                      >
                        <img
                          alt="Shine"
                          src={shine}
                          height="60px"
                          className={css({
                            position: "absolute",
                            bottom: "20px",
                            right: "20px",
                          })}
                        />
                      </div>
                      <Block
                        overrides={{
                          Block: {
                            style: {
                              borderTopWidth: "1px",
                              borderRightWidth: "1px",
                              borderBottomWidth: "1px",
                              borderLeftWidth: "1px",
                              borderTopStyle: "solid",
                              borderRightStyle: "solid",
                              borderBottomStyle: "solid",
                              borderLeftStyle: "solid",
                              borderTopColor:
                                theme.colors.backgroundLightAccent,
                              borderRightColor:
                                theme.colors.backgroundLightAccent,
                              borderBottomColor:
                                theme.colors.backgroundLightAccent,
                              borderLeftColor:
                                theme.colors.backgroundLightAccent,
                              backgroundColor: theme.colors.backgroundPrimary,
                              padding: theme.sizing.scale1000,
                              boxSizing: "border-box",
                              position: "relative",
                              transitionProperty: "all",
                              transitionDuration: "200ms",
                              transitionTimingFunction: "ease-in-out",
                              marginTop: theme.sizing.scale1000,
                              "::after": {
                                position: "absolute",
                                content: "''",
                                width: "75%",
                                height: "16px",
                                top: "100%",
                                left: "0px",
                                background:
                                  "linear-gradient(90deg, rgba(60,0,220,1) 0%, rgba(0,220,230,1) 100%)",
                              },
                            },
                          },
                        }}
                      >
                        <div
                          className={css({
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          })}
                        >
                          <LabelLarge color="contentPrimary">
                            <ContentItem
                              scope="AboutUs"
                              accessor={`panels.${panelIndex}.texts.0.title`}
                            />
                          </LabelLarge>
                        </div>

                        <ParagraphLarge
                          paddingTop="scale600"
                          paddingBottom="scale200"
                          $style={{
                            transitionProperty: "all",
                            transitionDuration: "200ms",
                            transitionTimingFunction: "ease-in-out",
                          }}
                        >
                          <FormattedContentItem
                            scope="AboutUs"
                            accessor={`panels.${panelIndex}.texts.0.caption`}
                          />
                        </ParagraphLarge>
                      </Block>
                    </div>
                  );
                }}
              </Spring>
            </Cell>
            <Cell span={[4, 3, 4]}>
              <Spring
                key={panelIndex}
                config={{ tension: 120, friction: 14, duration: 200 }}
                from={{ opacity: 0.8, bottom: 100 }}
                to={{ opacity: 1, bottom: 0 }}
              >
                {({ bottom, ...style }) => {
                  return (
                    <div
                      className={css({
                        width: "100%",
                        height: `calc(100% + ${theme.sizing.scale3200} + ${theme.sizing.scale4800} + ${theme.sizing.scale4800})`,
                        display: "flex",
                        marginTop: `-${theme.sizing.scale4800}`,
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-end",
                        transform: `translateY(${bottom}px)`,
                        ...style,
                        [theme.breakpoints.mediaQueries.medium]: {
                          marginTop: theme.sizing.scale1000,
                          height: "auto",
                          flexDirection: "row",
                          alignItems: "stretch",
                        },
                      })}
                    >
                      <Block
                        overrides={{
                          Block: {
                            style: {
                              borderTopWidth: "1px",
                              borderRightWidth: "1px",
                              borderBottomWidth: "1px",
                              borderLeftWidth: "1px",
                              borderTopStyle: "solid",
                              borderRightStyle: "solid",
                              borderBottomStyle: "solid",
                              borderLeftStyle: "solid",
                              borderTopColor:
                                theme.colors.backgroundLightAccent,
                              borderRightColor:
                                theme.colors.backgroundLightAccent,
                              borderBottomColor:
                                theme.colors.backgroundLightAccent,
                              borderLeftColor:
                                theme.colors.backgroundLightAccent,
                              backgroundColor: theme.colors.backgroundPrimary,
                              padding: theme.sizing.scale1000,
                              boxSizing: "border-box",
                              position: "relative",
                              transitionProperty: "all",
                              transitionDuration: "200ms",
                              transitionTimingFunction: "ease-in-out",
                              marginBottom: theme.sizing.scale1000,
                              "::after": {
                                position: "absolute",
                                content: "''",
                                height: "75%",
                                width: "16px",
                                top: "0%",
                                left: "100%",
                                background:
                                  "linear-gradient(180deg, rgba(60,0,220,1) 0%, rgba(0,220,230,1) 100%)",
                                [theme.breakpoints.mediaQueries.medium]: {
                                  display: "none",
                                },
                              },
                            },
                          },
                        }}
                      >
                        <div
                          className={css({
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          })}
                        >
                          <LabelLarge color="contentPrimary">
                            <ContentItem
                              scope="AboutUs"
                              accessor={`panels.${panelIndex}.texts.1.title`}
                            />
                          </LabelLarge>
                        </div>

                        <ParagraphLarge
                          paddingTop="scale600"
                          paddingBottom="scale200"
                          $style={{
                            transitionProperty: "all",
                            transitionDuration: "200ms",
                            transitionTimingFunction: "ease-in-out",
                          }}
                        >
                          <FormattedContentItem
                            scope="AboutUs"
                            accessor={`panels.${panelIndex}.texts.1.caption`}
                          />
                        </ParagraphLarge>
                      </Block>
                      <div
                        className={css({
                          height: "45%",
                          width: "75%",
                          borderTopWidth: "1px",
                          borderRightWidth: "1px",
                          borderBottomWidth: "1px",
                          borderLeftWidth: "1px",
                          borderTopStyle: "solid",
                          borderRightStyle: "solid",
                          borderBottomStyle: "solid",
                          borderLeftStyle: "solid",
                          borderTopColor: theme.colors.backgroundLightAccent,
                          borderRightColor: theme.colors.backgroundLightAccent,
                          borderBottomColor: theme.colors.backgroundLightAccent,
                          borderLeftColor: theme.colors.backgroundLightAccent,
                          backgroundImage: `url(${panelsImg1})`,
                          backgroundSize: "cover",
                          position: "relative",
                          [theme.breakpoints.mediaQueries.medium]: {
                            display: "none",
                          },
                        })}
                      ></div>
                    </div>
                  );
                }}
              </Spring>
            </Cell>
          </Grid>
        </div>
      </article>
    );
  } else return null;
};

export default Panels;
