import React, { useRef, useEffect } from "react";
import { useStyletron } from "baseui";

import { Lottie } from "@crello/react-lottie";

import videoData from "./about-us-video.json";

const Video = () => {
  const [css, theme] = useStyletron();
  const animationRef = useRef();

  let scrollTimeout;

  useEffect(() => {
    scrollTimeout = setTimeout(() => {
      animationRef.current.pause();
      setTimeout(() => {
        if (window.scrollY < window.innerHeight / 2) {
          document
            .querySelector("[data-csweb=about-us-timeline]")
            .scrollIntoView({
              behavior: "smooth",
            });
        }
      }, 400);
    }, 9000);
  }, []);

  useEffect(() => () => clearTimeout(scrollTimeout), []);

  return (
    <article data-csweb="about-us-video">
      <div
        data-csweb="video-wrapper"
        className={css({
          width: "100vw",
          height: "105vh",
          transitionProperty: "all",
          transitionDuration: "200ms",
          transitionTimingFunction: "ease-in-out",
          background: `linear-gradient(90deg, ${theme.colors.black100} 0%, ${theme.colors.primary} 100%)`,
        })}
      >
        <div
          className={css({
            width: "100%",
            height: "100%",
            [theme.breakpoints.mediaQueries.medium]: {
              width: "100%",
              height: "50%",
              top: "25%",
              position: "relative",
            },
          })}
        >
          <Lottie
            config={{
              loop: false,
              autoplay: true,
              animationData: videoData,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            isClickToPauseDisabled={true}
            height={"100%"}
            width={"100%"}
            animationRef={animationRef}
          />
        </div>
      </div>
    </article>
  );
};

export default Video;
